import XDate from 'xdate';
import React, { useRef, useMemo, useCallback } from 'react';
import { ScrollView, View } from 'react-native';
import { getPartialWeekDates, getWeekDates, sameMonth } from '../dateutils';
import { parseDate, toMarkingFormat } from '../interface';
import { getState } from '../day-state-manager';
import { extractDayProps } from '../componentUpdater';
import styleConstructor from './style';
import Calendar from '../calendar';
import Day from '../calendar/day/index';
const Week = (props) => {
    const { theme, current, firstDay, hideWeekend = false,hideWeek = false, hideExtraDays, markedDates, onDayPress, onDayLongPress, style: propsStyle, numberOfDays = 1, timelineLeftInset } = props;
    const style = useRef(styleConstructor(theme));
    const dayProps = extractDayProps(props);
    const currXdate = parseDate(current);
    const getWeek = useCallback((date) => {
        if (date) {
            return getWeekDates(date, firstDay);
        }
    }, [firstDay]);
    // renderWeekNumber (weekNumber) {
    //   return <BasicDay key={`week-${weekNumber}`} theme={this.props.theme} marking={{disableTouchEvent: true}} state='disabled'>{weekNumber}</BasicDay>;
    // }
    const renderDay = (day, id) => {
        if(hideWeekend && (id === 0 || id === 6)) {
            return null;
        }
        if(hideWeek && (id !== 5 && id !== 6)) {
            return null;
        }
        // hide extra days
        if (current && hideExtraDays) {
            if (!sameMonth(day, currXdate)) {
                return <View key={id} style={style.current.emptyDayContainer}/>;
            }
        }
        return (<ScrollView contentContainerStyle={[style.current.dayContainer]} testID={"day-scroll"} style={{flex: 1, height: "100%"}} key={id}>
        <Day {...dayProps} date={toMarkingFormat(day)} state={getState(day, currXdate, props)} marking={markedDates?.[toMarkingFormat(day)]} onPress={onDayPress} onLongPress={onDayLongPress}/>
      </ScrollView>);
    };
    const renderWeek = () => {
        const dates = numberOfDays > 1 ? getPartialWeekDates(current, numberOfDays) : getWeek(current);
        const week = [];
        if (dates) {
            const todayIndex = dates?.indexOf(parseDate(new Date())) || -1;
            const sliced = dates.slice(todayIndex, numberOfDays);
            const datesToRender = numberOfDays > 1 && todayIndex > -1 ? sliced : dates;
            datesToRender.forEach((day, id) => {
                const d = day instanceof XDate ? day : new XDate(day);
                week.push(renderDay(d, id));
            }, this);
        }
        return week;
    };
    const partialWeekStyle = useMemo(() => {
        return [style.current.partialWeek, { paddingLeft: timelineLeftInset }];
    }, [timelineLeftInset]);
    return (<View testID='week-outer-container' style={[style.current.container, { height: "100%"}]}>
      <View style={[style.current.week, numberOfDays > 1 ? partialWeekStyle : undefined, propsStyle, { height: "100%"}]} testID='week-inner-container'>{renderWeek()}</View>
    </View>);
};
export default Week;
Week.displayName = 'Week';
Week.propTypes = {
    ...Calendar.propTypes
};

import React from 'react';
import { Text } from 'react-native';
import { weekDayNames } from '../dateutils';
const WeekDaysNames = React.memo(({ firstDay, style, hideWeekend = false, hideWeek = false }) => {
    const dayNames = weekDayNames(firstDay);
    return dayNames.map((day, index) => (((hideWeekend && (index === 0 || index === 6)) || (hideWeek && (index !== 5 && index !== 6))) ? null : <Text allowFontScaling={false} key={index} style={style} numberOfLines={1} accessibilityLabel={''}>
      {day}
    </Text>));
});
export default WeekDaysNames;

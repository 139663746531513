import _some from "lodash/some";
import _defaults from "lodash/defaults";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React from "react";
import * as Collection from "../collection";
import * as Transitions from "../transitions";
var INITIAL_STATE = {
  nodesShouldLoad: false,
  nodesDoneLoad: false,
  animating: true
};
export var useAnimationState = function () {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;

  var _React$useState = React.useState(initialState),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      state = _React$useState2[0],
      _setState = _React$useState2[1]; // This allows us to use a state object and maintain the same API as this.setState


  var setState = React.useCallback(function (newState) {
    _setState(function (oldState) {
      return _objectSpread(_objectSpread({}, oldState), newState);
    });
  }, [_setState]); // This is a copy of Wrapper.getAnimationProps

  var getAnimationProps = React.useCallback(function (props, child, index) {
    if (!(props !== null && props !== void 0 && props.animate)) {
      return child.props.animate;
    }

    var getFilteredState = function () {
      var childrenTransitions = state && state.childrenTransitions;
      childrenTransitions = Collection.isArrayOfArrays(childrenTransitions) ? childrenTransitions[index] : childrenTransitions;
      return _defaults({
        childrenTransitions: childrenTransitions
      }, state);
    };

    var getTransitions = props.animate && props.animate.getTransitions;
    var filteredState = getFilteredState();
    var parentState = props.animate && props.animate.parentState || filteredState;

    if (!getTransitions) {
      var getTransitionProps = Transitions.getTransitionPropsFactory(props, filteredState, function (newState) {
        return setState(newState);
      });

      getTransitions = function (childComponent) {
        return getTransitionProps(childComponent, index);
      };
    }

    return _defaults({
      getTransitions: getTransitions,
      parentState: parentState
    }, props.animate, child.props.animate);
  }, [state, setState]); // This is a copy of Wrapper.setAnimationState

  var setAnimationState = React.useCallback(function (props, nextProps) {
    if (!(props !== null && props !== void 0 && props.animate)) {
      return;
    }

    if (props.animate.parentState) {
      var nodesWillExit = props.animate.parentState.nodesWillExit;
      var oldProps = nodesWillExit ? props : null;

      var newState = _defaults({
        oldProps: oldProps,
        nextProps: nextProps
      }, props.animate.parentState);

      setState(newState);
    } else {
      var oldChildren = React.Children.toArray(props.children);
      var nextChildren = React.Children.toArray(nextProps.children);

      var isContinuous = function (child) {
        var check = function (c) {
          return c.type && c.type.continuous;
        };

        return Array.isArray(child) ? _some(child, check) : check(child);
      };

      var continuous = !props.polar && _some(oldChildren, function (child) {
        return isContinuous(child) || child.props.children && isContinuous(child.props.children);
      });

      var _Transitions$getIniti = Transitions.getInitialTransitionState(oldChildren, nextChildren),
          _nodesWillExit = _Transitions$getIniti.nodesWillExit,
          nodesWillEnter = _Transitions$getIniti.nodesWillEnter,
          childrenTransitions = _Transitions$getIniti.childrenTransitions,
          nodesShouldEnter = _Transitions$getIniti.nodesShouldEnter;

      setState({
        nodesWillExit: _nodesWillExit,
        nodesWillEnter: nodesWillEnter,
        nodesShouldEnter: nodesShouldEnter,
        childrenTransitions: Collection.isArrayOfArrays(childrenTransitions) ? childrenTransitions[0] : childrenTransitions,
        oldProps: _nodesWillExit ? props : undefined,
        nextProps: nextProps,
        continuous: continuous
      });
    }
  }, [setState]);
  var getProps = React.useCallback(function (initialProps) {
    return state && state.nodesWillExit ? state.oldProps || initialProps : initialProps;
  }, [state]);
  return {
    state: state,
    setState: setState,
    getAnimationProps: getAnimationProps,
    setAnimationState: setAnimationState,
    getProps: getProps
  };
};